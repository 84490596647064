import React, {useState, useEffect} from 'react';
import firebase from '../firebase';


const LyricsContent = () => {

    const [transcripts, setTranscripts] = useState([]);
    const [loading, setLoading] = useState(false);

    const ref = firebase.firestore().collection("transcripts");

    function getTranscripts(){
        setLoading(true);
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            setTranscripts(items);
            setLoading(false);
        })
    }

    useEffect(() => {
        getTranscripts();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    return(
        <div className="lyrics-content">
            {/* <h2>Song Title</h2> */}
            {transcripts.map((transcript) => (
                <div className="lyrics-content" key={transcript.id}>
                <h2>{transcript.title}</h2>
                <p>{transcript.text}</p>
                </div>
            ))}
            
            {/* <div className="song-credits">
                <h3>Written by:</h3>
                <h3>Sung by:</h3>
                <h3>Produced by:</h3>
            </div> */}
            
        </div>
    )
}

export default LyricsContent;