import firebase from "firebase";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBN3tdHhD288tkUl7SSqbx3i86MMeaRQ6A",
    authDomain: "next-gen-radio.firebaseapp.com",
    projectId: "next-gen-radio",
    storageBucket: "next-gen-radio.appspot.com",
    messagingSenderId: "2386877563",
    appId: "1:2386877563:web:c0b8f9e03fe0159feed6de"
  };

  firebase.initializeApp(firebaseConfig);

  export default firebase;