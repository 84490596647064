import { v4 as uuidv4 } from "uuid";

function Hop() {
  
  return [
    {
      name: "On Air",
      cover:
        "https://i.vimeocdn.com/video/1122052996-010b2e55ebacf61c3b3509fe9b605765e7e44aa5f3b4182be712afcce9903e25-d?mw=1200&mh=675",
      artist: "TEA RADIO",
      audio: "https://stlradio.out.airtime.pro/stlradio_a",
      color: ["#205950", "#2ab3bf"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Español | Spanish",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Reunión Cibernética",
      audio: "https://songsthatlive.org/radio/8000/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Français | French",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Cyber-Rencontre",
      audio: "https://songsthatlive.org/radio/8020/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Creole",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Cyber-Rencontre",
      audio: "https://songsthatlive.org/radio/8060/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Português | Portuguese",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Cyber Companheirismo",
      audio: "https://songsthatlive.org/radio/8010/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "русский | Russian",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Кибер Содружество",
      audio: "https://songsthatlive.org/radio/8070/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Română | Romanian",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Cyber ​​Fellowship",
      audio: "https://songsthatlive.org/radio/8090/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Deutsche | German",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Cyber Gemeinschaft",
      audio: "https://songsthatlive.org/radio/8030/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "chiShona | Shona",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Musangano weCyber",
      audio: "https://songsthatlive.org/radio/8100/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Kiswahili | Swahili",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Mkutano wa Mtandaoni",
      audio: "https://songsthatlive.org/radio/8050/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "हिंदी | Hindi",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "साइबर बैठक",
      audio: "https://songsthatlive.org/radio/8110/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "తెలుగు | Telugu",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "సైబర్ సమావేశం",
      audio: "https://songsthatlive.org/radio/8120/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "bahasa Indonesia | Indonesian",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Pertemuan Cyber",
      audio: "https://songsthatlive.org/radio/8130/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Cebuano",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Tigum sa Cyber",
      audio: "https://songsthatlive.org/radio/8160/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Tagalog",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Cyber Fellowship",
      audio: "https://songsthatlive.org/radio/8150/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "Tiếng Việt | Vietnamese",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "Cuộc họp trực tuyến",
      audio: "https://songsthatlive.org/radio/8040/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    {
      name: "اردو | Urdu",
      cover:
        "https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjAwMTU4MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080",
      artist: "سائبر میٹنگ",
      audio: "https://songsthatlive.org/radio/8140/radio.mp3",
      color: ["#EF8EA9", "#ab417f"],
      id: uuidv4(),
      title: "Live Radio Playlist",
      type: "radio",
      active: false,
    },
    //------------------------------------ NEW MUSIC SECTION - 10 TRACKS CONTAINED - 12.07.21 START ------------------------------------
    {
      name: "The Secret Of The Access",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086349303-user-901971771-the-secret-of-the-access.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    {
      name: "Greater Is He",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086349312-user-901971771-greater-is-he.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    {
      name: "My Faith is Zeroed to The Change - Minha Fé Está Firmada na Transformação",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086349306-user-901971771-my-faith-is-zeroed-to-the.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    {
      name: "We Stand As One",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086349318-user-901971771-we-stand-as-one.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    {
      name: "Come Let Us Stand",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086349330-user-901971771-come-let-us-stand.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    {
      name: "Sua Palavra Está Acima de Tudo",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "Assim Está Escrito (So It Is Written) | Brazil",
      audio: "https://feeds.soundcloud.com/stream/1086349333-user-901971771-sua-palavra-esta-acima-de-tudo.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    {
      name: "Arise and Be Doing",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086349342-user-901971771-arise-and-be-doing.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    {
      name: "Hoje eu Quero Cristo",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "Assim Está Escrito (So It Is Written) | Brazil",
      audio: "https://feeds.soundcloud.com/stream/1086349363-user-901971771-hoje-eu-quero-cristo.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    {
      name: "Opening Of Seals",
      cover:
        "https://images.unsplash.com/photo-1512778595306-cd1a150f561c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=2800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTY4NzAwOQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=2800",
      artist: "A Voz do Profeta (The Prophet’s Voice)  |  Criciuma, Brazil",
      audio: "https://feeds.soundcloud.com/stream/1086349366-user-901971771-a-voz-do-profeta-opening-of.mp3",
      color: ["#F25C2F", "#FBEA27"],
      id: uuidv4(),
      title: "New Releases Playlist",
      type: "new",
      active: false,
    },
    //------------------------------------ NEW MUSIC SECTION - 10 TRACKS CONTAINED - 12.07.21 END ------------------------------------
    //********************************************************************************************************************************
     //------------------------------------ INSTRUMENTAL MUSIC SECTION - 10 TRACKS CONTAINED - 12.07.21 START ------------------------------------
    {
      name: "Come Holy Spirit",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756336-user-901971771-come-holy-spirit.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "Draw Me Nearer",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756324-user-901971771-draw-me-nearer.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "He Looked Beyond My Fault",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756309-user-901971771-he-looked-beyond-my-fault.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "Is Your All On The Altar",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756300-user-901971771-is-your-all-on-the-altar.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "It's A Lovely Lovely Name",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756291-user-901971771-its-a-lovely-lovely-name.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "Jesus Jesus",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756276-user-901971771-jesus-jesus.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "Let Us Draw Near",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756264-user-901971771-let-us-draw-near.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "My Faith Looks Up To Thee",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756252-user-901971771-my-faith-looks-up-to-thee.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "Surely The Presence",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756246-user-901971771-surely-the-presence.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "Sweet Hour of Prayer",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756234-user-901971771-sweet-hour-of-prayer.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    {
      name: "Teach Me Lord To Wait",
      cover:
        "https://images.unsplash.com/photo-1575484140524-cc2bf773f49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNTg2NDIzMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1084756225-user-901971771-teach-me-lord-to-wait.mp3",
      color: ["#008EDC", "#ADD8E6"],
      id: uuidv4(),
      title:"Instrumentals Playlist",
      type: "instrumental",
      active: false,
    },
    //------------------------------------ INSTRUMENTAL MUSIC SECTION - 10 TRACKS CONTAINED - 12.07.21 END ------------------------------------

    //********************************************************************************************************************************
    //------------------------------------ SCHOOL SECTION - 10 TRACKS CONTAINED - 12.07.21 START ------------------------------------
    {
      name: "Rise Up On The Wings Of Faith",
      cover:
        "https://images.unsplash.com/photo-1519460236381-fa925d54e0bc?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTgwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1087112437-user-901971771-rise-up-on-the-wings-of-faith.mp3",
      color: ["#F2AB02", "#FED100"],
      id: uuidv4(),
      title:"Sunday School Playlist",
      type: "school",
      active: false,
    },
    {
      name: "Beauty Of God's Love",
      cover:
        "https://images.unsplash.com/photo-1519460236381-fa925d54e0bc?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTgwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086387073-user-901971771-beauty-of-gods-love.mp3",
      color: ["#F2AB02", "#FED100"],
      id: uuidv4(),
      title:"Sunday School Playlist",
      type: "school",
      active: false,
    },
    {
      name: "Comfort Of My Soul",
      cover:
        "https://images.unsplash.com/photo-1519460236381-fa925d54e0bc?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTgwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086387070-user-901971771-comfort-of-my-soul.mp3",
      color: ["#F2AB02", "#FED100"],
      id: uuidv4(),
      title:"Sunday School Playlist",
      type: "school",
      active: false,
    },
    {
      name: "I Want To Go",
      cover:
        "https://images.unsplash.com/photo-1519460236381-fa925d54e0bc?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTgwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086387064-user-901971771-i-want-to-go.mp3",
      color: ["#F2AB02", "#FED100"],
      id: uuidv4(),
      title:"Sunday School Playlist",
      type: "school",
      active: false,
    },
    {
      name: "I Will Be Changed",
      cover:
        "https://images.unsplash.com/photo-1519460236381-fa925d54e0bc?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTgwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086387061-user-901971771-i-will-be-changed.mp3",
      color: ["#F2AB02", "#FED100"],
      id: uuidv4(),
      title:"Sunday School Playlist",
      type: "school",
      active: false,
    },
    {
      name: "In Him I Will Abide",
      cover:
        "https://images.unsplash.com/photo-1519460236381-fa925d54e0bc?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTgwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086387055-user-901971771-in-him-i-will-abide.mp3",
      color: ["#F2AB02", "#FED100"],
      id: uuidv4(),
      title:"Sunday School Playlist",
      type: "school",
      active: false,
    },
    {
      name: "Walk Closer",
      cover:
        "https://images.unsplash.com/photo-1519460236381-fa925d54e0bc?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTgwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086387037-user-901971771-walk-closer.mp3",
      color: ["#F2AB02", "#FED100"],
      id: uuidv4(),
      title:"Sunday School Playlist",
      type: "school",
      active: false,
    },
    {
      name: "Stature Of A Perfect Man",
      cover:
        "https://images.unsplash.com/photo-1519460236381-fa925d54e0bc?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTgwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1000",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086388780-user-901971771-stature-of-a-perfect-man.mp3",
      color: ["#F2AB02", "#FED100"],
      id: uuidv4(),
      title:"Sunday School Playlist",
      type: "school",
      active: false,
    },
    //------------------------------------ SCHOOL SECTION - 10 TRACKS CONTAINED - 12.07.21 END ------------------------------------
    //********************************************************************************************************************************
    //------------------------------------ CONGREGATIONAL SECTION - 10 TRACKS CONTAINED - 12.07.21 START ------------------------------------
    {
      name: "Do You Know Grace",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400708-user-901971771-do-you-know-grace.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "Got Any Rivers",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400705-user-901971771-got-any-rivers.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "He Came To Me",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400690-user-901971771-he-came-to-me-congregational.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "I Stand In Awe",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400681-user-901971771-i-stand-in-awe-congregational.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "I Want To Go",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400675-user-901971771-i-want-to-go-1.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "It's The Anointing",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400660-user-901971771-its-the-anointing.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "Rapturing Faith",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400657-user-901971771-rapturing-faith-congregational.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "Take Me Separate Me",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400651-user-901971771-take-me-separate-me.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "Tis So Sweet",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086400642-user-901971771-tis-so-sweet-congregational.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    {
      name: "I Want To Live",
      cover:
        "https://images.unsplash.com/photo-1478147427282-58a87a120781?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1200&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjEzOTAzNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1200",
      artist: "TEA | Caribbean",
      audio: "https://feeds.soundcloud.com/stream/1086412423-user-901971771-i-want-to-live-he-wants-me-to-live-congrregational.mp3",
      color: ["#2C2D2A", "#776D62"],
      id: uuidv4(),
      title: "Congregational Playlist",
      type: "congregational",
      active: false,
    },
    //------------------------------------ CONGREGATIONAL SECTION - 10 TRACKS CONTAINED - 12.07.21 END ------------------------------------
  ];
}

export default Hop;
