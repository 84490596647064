import React, {useState, useMemo } from 'react';
import LibrarySong from './LibrarySong';
//SVGs as Components
import { ReactComponent as ChevronIcon } from '../icons/chevron.svg';
import { ReactComponent as ArrowIcon } from '../icons/arrow.svg';
import { CSSTransition } from 'react-transition-group';
import { mapValues, groupBy, omit } from 'lodash';

function DropdownItem(props){
    return (
        <a href="#" className="menu-item" onClick={() => props.goToMenu && props.setActiveMenu(props.goToMenu)}>
            <span className="icon-button">{props.leftIcon}</span>

            {props.children}

            <span className="icon-right">{props.rightIcon}</span>
        </a>
    )
}


const Library = ({songs, setCurrentSong, audioRef, isPlaying, setSongs, libraryStatus}) => {

    const [activeMenu, setActiveMenu] = useState('main'); //settings, Library

    // const radio = songs.filter(song => song.type === 'radio');
    // const newbie = songs.filter(song => song.type === 'new');
    // const instrumental = songs.filter(song => song.type === 'instrumental');
    // const school = songs.filter(song => song.type === 'school');
    // const congregational = songs.filter(song => song.type === 'congregational');

    // const songTypes = mapValues(groupBy(songs, ['type']), flist => flist.map(item => omit(item, 'type')))
    const songTypes = useMemo(() => {
        return groupBy([...songs], 'type')
    }, [songs])
    

    const nodeRef = React.useRef(null)

    return(
        <div className={`library ${libraryStatus ? 'active-library' : ''}`}>
            <CSSTransition
                nodeRef={nodeRef}
                in={activeMenu === 'main'} 
                unmountOnExit 
                timeout={500}
                classNames="menu-primary">
                    <div ref={nodeRef} className="menu">
                    <h2>Playlist Library</h2>
                    <DropdownItem
                        leftIcon="🎙️"
                        rightIcon={<ChevronIcon/>}
                        setActiveMenu={setActiveMenu}
                        goToMenu="radio">
                        <div className="live-button">Live Radio</div>
                    </DropdownItem>
                    <DropdownItem
                        leftIcon="🕊️"
                        rightIcon={<ChevronIcon/>}
                        setActiveMenu={setActiveMenu}
                        goToMenu="new">
                        New Releases
                    </DropdownItem>
                    <DropdownItem
                        leftIcon="🎹"
                        rightIcon={<ChevronIcon/>}
                        setActiveMenu={setActiveMenu}
                        goToMenu="instrumental">
                        Instrumentals
                    </DropdownItem>
                    <DropdownItem
                        leftIcon="👪"
                        rightIcon={<ChevronIcon/>}
                        setActiveMenu={setActiveMenu}
                        goToMenu="school">
                        Sunday School
                    </DropdownItem>
                    <DropdownItem
                        leftIcon="⛪"
                        rightIcon={<ChevronIcon/>}
                        setActiveMenu={setActiveMenu}
                        goToMenu="congregational">
                        Congregational 
                    </DropdownItem>
                    </div>
                
            </CSSTransition>

            {Object.keys(songTypes).map((songType, index) => {
                return (
                    <SubMenu 
                        idx={index}
                        songType={songType} 
                        title={`${songType}`} 
                        data={songTypes[songType]} 
                        songs={songs}
                        activeMenu={activeMenu}
                        setActiveMenu={setActiveMenu}
                        setCurrentSong={setCurrentSong}
                        audioRef={audioRef}
                        isPlaying={isPlaying}
                        setSongs={setSongs}
                    />
                )
            })}

        </div>
    )
}

export default Library;

const SubMenu = ({
    idx,
    songType,
    title,
    data,
    songs,
    activeMenu,
    setActiveMenu,
    setCurrentSong,
    audioRef,
    isPlaying,
    setSongs
}) => {

    const subMenuNodeRef = React.useRef(null)
    return (
            <CSSTransition
                nodeRef={subMenuNodeRef}
                key={`list-${idx}`}
                in={activeMenu === songType} 
                unmountOnExit 
                timeout={500}
                classNames="menu-secondary">
                    <div ref={subMenuNodeRef} className="menu">
                    <DropdownItem leftIcon={<ArrowIcon/>} setActiveMenu={setActiveMenu} goToMenu="main"/>
                        <h2>{data[0].title}</h2>
                        <div className="library-songs">
                            {data.map((song)=>(
                            <LibrarySong 
                            songs={songs} 
                            setCurrentSong={setCurrentSong} 
                            song={song} 
                            id={song.id}
                            key={song.id}
                            audioRef={audioRef}
                            isPlaying={isPlaying}
                            setSongs={setSongs}
                            />
                            ))}
                        </div>
                    </div>
            </CSSTransition>
    )
}