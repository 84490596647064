import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    borderRadius: '15px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
    height: '60px',
    width: '60px',
  },
  listenerscontent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    // backgroundColor: 'lightblue',
    padding: '0px',
  },
  personname: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  borders: {
    borderColor: 'lightgrey',
  },
  country: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 25,
      height: 25,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);

export default function ListenersCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title="12,727 Listeners"
        // subheader="12 on stage"
        titleTypographyProps={{variant:'h6', color:'textSecondary'}}
      />
      <Box borderTop={2} className={classes.borders}></Box>
      <CardContent className={classes.country}>
        <Typography variant="body1" color="textPrimary" component="p">
          Germany
        </Typography>
        <Badge badgeContent={312} max={999} color="primary"/>
      </CardContent>
      <CardContent className={classes.listenerscontent}>
        <div className={classes.avatarname}>
          <Avatar alt="Eemy Sharp" src="/avatars/1.jpg" className={classes.avatar}/>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
        <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src="/avatars/1.jpg" />}
      >
        <Avatar alt="Travis Howard" src="/avatars/7.jpg" className={classes.avatar} />
      </Badge>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
        <Avatar alt="Travis Howard" src="/avatars/8.jpg" className={classes.avatar} />
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
        <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src="/avatars/9.jpg" />}
      >
        <Avatar alt="Travis Howard" src="/avatars/6.jpg" className={classes.avatar} />
      </Badge>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
        <Avatar alt="Travis Howard" src="/avatars/9.jpg" className={classes.avatar} />
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        </CardContent>
      <CardContent className={classes.country}>
        <Typography variant="body1" color="textPrimary" component="p">
          France
        </Typography>
        <Badge badgeContent={144} max={999} color="primary"/>
      </CardContent>
      <CardContent className={classes.listenerscontent}>
        <div className={classes.avatarname}>
        <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src="/avatars/9.jpg" />}
      >
        <Avatar alt="Travis Howard" src="/avatars/11.jpg" className={classes.avatar} />
      </Badge>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
        <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src="/avatars/5.jpg" />}
      >
        <Avatar alt="Travis Howard" src="/avatars/12.jpg" className={classes.avatar} />
      </Badge>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
        <Avatar alt="Travis Howard" src="/avatars/10.jpg" className={classes.avatar} />
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
        <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src="/avatars/8.jpg" />}
      >
        <Avatar alt="Travis Howard" src="/avatars/1.jpg" className={classes.avatar} />
      </Badge>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
        <Avatar alt="Travis Howard" src="/avatars/3.jpg" className={classes.avatar} />
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        </CardContent>
      <CardActions disableSpacing>
      <Typography variant="body1" color="textPrimary" component="p">View More</Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
        <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent className={classes.country}>
        <Typography variant="body1" color="textPrimary" component="p">
          Indonesia
        </Typography>
        <Badge badgeContent={505} max={999} color="primary"/>
      </CardContent>
      <CardContent className={classes.listenerscontent}>
        <div className={classes.avatarname}>
          <Avatar aria-label="recipe" className={classes.avatar}>
            A
          </Avatar>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
          <Avatar aria-label="recipe" className={classes.avatar}>
            B
          </Avatar>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
          <Avatar aria-label="recipe" className={classes.avatar}>
            C
          </Avatar>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
          <Avatar aria-label="recipe" className={classes.avatar}>
            D
          </Avatar>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        <div className={classes.avatarname}>
          <Avatar aria-label="recipe" className={classes.avatar}>
            D
          </Avatar>
          <Typography paragraph className={classes.personname}>James Morris</Typography>
        </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}
