import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RoomCard from './RoomCard';
import SpeakerCard from './SpeakerCard';
import ListenersCard from './ListenersCard';


const useStyles = makeStyles((theme) => ({
    root: {
      // maxWidth: 345,
      backgroundColor: 'lightgrey',
    },
  }));


const Room = () => {
    const classes = useStyles();

    return(
        <Container className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <RoomCard/>
                    
                </Grid>
                    <Grid item xs={12} sm={6}>
                        <SpeakerCard/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ListenersCard/>
                    </Grid>
            </Grid>
        </Container>
    )
}

export default Room;