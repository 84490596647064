import React from 'react';

const LibrarySong = ({song, songs, setCurrentSong, id, audioRef, isPlaying, setSongs, setLibraryStatus, libraryStatus}) => {
const songSelecthandler = async () => {
    await setCurrentSong(song);
    audioRef.current.play(); // NOT SURE IF THIS SHOULD BE HERE EMEKA
    //Add Active State
    const newSongs = songs.map((song) => {
        if(song.id === id){
            return{
                ...song,
                active: true,
            }
        }else{
            return{
                ...song,
                active: false,
            }
        }
    })
    setSongs(newSongs)

    //check if the song is playing
    if(isPlaying) audioRef.current.play();
    
}


    return(
        <div onClick={songSelecthandler} className={`library-song ${song.active ? 'selected' : ""}`}>
            <img alt={song.name} src={song.cover}></img>
            <div className="song-description">
            <h3>{song.name}</h3>
            <h4>{song.artist}</h4>
            </div>
        </div>
    )
}

export default LibrarySong;