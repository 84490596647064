import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    // paddingBottom: '0px',
    borderRadius: '15px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  mainavatar: {
    backgroundColor: red[500],
    height: '95px',
    width: '95px',
  },
  avatarname: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '10px',
  },
  avatar: {
    backgroundColor: red[500],
    height: '60px',
    width: '60px',
  },
  speakercontent: {
      display: 'flex',
      flexDirection: 'row',
  },
  speakersubcontent: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  interpretercontent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    // backgroundColor: 'lightblue',
    padding: '0px',
    WebkitOverflowScrolling: 'auto',
    scrollbarColor: 'white',
  },
  personname: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  borders: {
    borderColor: 'lightgrey',
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function SpeakerCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title="12 On Stage"
        // subheader="12 on stage"
        titleTypographyProps={{variant:'h6', color:'textSecondary'}}
      />
      <Box borderTop={2} className={classes.borders}></Box>
      <CardContent className={classes.speakercontent}>
        <Avatar alt="Eemy Sharp" src="/avatars/2.jpg" className={classes.mainavatar}/>
          <CardContent>
            <Typography paragraph variant='h6'>Ever Montalvan</Typography>
            <Typography paragraph className={classes.speakersubcontent}>
            Zoe Tabernacle| Chiclaoy, Peru
            </Typography>
      </CardContent>
      </CardContent>
      
      <div className={classes.interpretercontent}>
      <div className={classes.chips}>
        <Chip
        avatar={<Avatar alt="Natacha" src="/avatars/1.jpg" />}
        label="Spanish"
        color="primary"
        />
      </div>
      <div className={classes.chips}>
        <Chip
        avatar={<Avatar alt="Natacha" src="/avatars/2.jpg" />}
        label="Portuguese"
        color="primary"
        />
      </div>
      <div className={classes.chips}>
        <Chip
        avatar={<Avatar alt="Natacha" src="/avatars/3.jpg" />}
        label="German"
        color="primary"
        />
      </div>
      <div className={classes.chips}>
        <Chip
        avatar={<Avatar alt="Natacha" src="/avatars/4.jpg" />}
        label="Vietnamese"
        color="primary"
        />
      </div>
      <div className={classes.chips}>
        <Chip
        avatar={<Avatar alt="Natacha" src="/avatars/5.jpg" />}
        label="Tagalog"
        color="primary"
        />
      </div>
      <div className={classes.chips}>
        <Chip
        avatar={<Avatar alt="Natacha" src="/avatars/6.jpg" />}
        label="Swahili"
        color="primary"
        />
      </div>
      <div className={classes.chips}>
        <Chip
        avatar={<Avatar alt="Natacha" src="/avatars/7.jpg" />}
        label="French"
        color="primary"
        />
      </div>
      </div>
    </Card>
  );
}
