import React, {useState} from 'react';
import Marquee from "react-fast-marquee";
import { Detector } from "react-detect-offline";
import oceanimage from "../asset/oceanimage.jpg"; 
import oceangif from "../asset/ocean-sea.gif";



const Song = ({currentSong, radioTitle}) => {


    var decoded_text = decodeURIComponent(currentSong.name);
    
    return(
        <div className="song-container">
            <Detector
            render={({ online }) => (
                <div className={online ? "normal" : "warning"}>
                Internet connection has been {online ? "established 📡" : "lost 📡"}
                </div>
            )} 
            />
            <div>
                <h1 className='tr-heading'>TEA RADIO</h1>
            </div>
            {/* <div className='img-container'>
                <img alt={currentSong.name} src={oceanimage}></img>
                <div className='text'>TEA RADIO</div>
            </div> */}
            {/* <div className='container'>
            <video autoplay loop muted>
               <source src="../asset/ocean.mp4" type="video/mp4"></source>
            </video>
            <div className='text-box'>
                    <h1>TEA</h1>
            </div>
            </div> */}
            <Marquee 
            speed={50}
            pauseOnClick={true}
            gradientWidth={100}
            >
            { (currentSong.type === 'radio') &&(
            <h2>
            {radioTitle.name} 
            </h2>
            )}
            { (currentSong.type === 'instrumental' || 'new' || 'congregational' || 'school') &&(
            <h2>
            🕊️{decoded_text}🕊️ 
            </h2>
            )}
            </Marquee>
            <h3>{currentSong.artist}</h3>
        </div>
    )
}

export default Song;