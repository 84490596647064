import React, {useState, useEffect, useRef} from 'react'
//Import Styles
import './styles/app.scss';
//Adding Components
import Player from './components/Player';
import Song from './components/Song';
import LyricsDrawer from './components/LyricsDrawer';
import Library from './components/Library';
import Room from './components/Room';
// import Nav from './components/Nav';
import AdvanceNavbar from './components/AdvanceNavbar';
import LyricsNav from './components/LyricsNav';
//Import Data
import data from './data';
import axios from "axios";

function App() {
   //Ref
   const audioRef = useRef(null);
  //State
  const [songs, setSongs] = useState(data());
  const [currentSong, setCurrentSong] = useState(songs[0]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [songInfo, setSongInfo] = useState({
    currentTime:0,
    duration:0,
    animationPercentage: 0,
});

const [libraryStatus, setLibraryStatus] = useState(false);
const [lyricsDrawerStatus, setLyricsDrawerStatus] = useState(false);

const timeUpdateHandler = (e) => {
    const current = e.target.currentTime;
    const duration = e.target.duration;
    // console.log(e.target.volume = 1);
    //Calculate Percentage
    const roundedCurrent = Math.round(current);
    const roundedDuration = Math.round(duration);
    const animation = Math.round((roundedCurrent / roundedDuration) * 100)
    setSongInfo({...songInfo, currentTime: current, duration, animationPercentage: animation,});
};
const songEndHandler = async () => {
  let currentIndex = songs.findIndex((song) => song.id === currentSong.id);
  await setCurrentSong(songs[(currentIndex + 1) % songs.length]);
  if(isPlaying) audioRef.current.play();     
}


// const[radioTitle, setRadioTitle] = useState({});
// useEffect(() => {
//   async function fetchData(){
//   await axios.get("http://stlradio.airtime.pro/api/live-info")
//   .then((myapi) => {
//     setRadioTitle(myapi.data.current);
//     console.log({radioTitle});
//     console.log(myapi.data.current.name + " without state");
//   })
//   .catch((err) => console.log(err));
// }
// fetchData();
// }, [])

// const[radioTitle, setRadioTitle] = useState({});
// useEffect(() => {
  
//   axios.get("http://stlradio.airtime.pro/api/live-info")
//   .then((myapi) => {
//     setRadioTitle(myapi.data.current);
//     console.log({radioTitle});
//     console.log(myapi.data.current.name + " without state");
//   })
//   .catch((err) => console.log(err));
// }, [])


const[radioTitle, setRadioTitle] = useState({});
useEffect(() => {
  let isMounted = true
  const intervalId = setInterval(() => { 

  axios.get("https://stlradio.airtime.pro/api/live-info")
  .then((myapi) => {
    if(!isMounted) return 
    setRadioTitle(myapi.data.current);
    // console.log({radioTitle});
    // console.log(myapi.data.current.name + " without state");
  })
  .catch((err) => console.log(err));

}, 10000)

return () => {
  clearInterval(intervalId); //This is important
}

}, [])

  return (
    <div className={`App ${libraryStatus ? 'library-active' : ''}`}>
      <AdvanceNavbar libraryStatus={libraryStatus} setLibraryStatus={setLibraryStatus}/>
      <LyricsNav lyricsDrawerStatus={lyricsDrawerStatus} setLyricsDrawerStatus={setLyricsDrawerStatus}/>
      <Song currentSong={currentSong}
            radioTitle={radioTitle}
      />
      <Player 
      audioRef={audioRef} 
      setIsPlaying={setIsPlaying} 
      isPlaying={isPlaying} 
      currentSong={currentSong}
      setSongInfo={setSongInfo}
      songInfo={songInfo}
      songs={songs}
      setCurrentSong={setCurrentSong}
      setSongs={setSongs}
      />
      <Library 
      audioRef={audioRef} 
      songs={songs} 
      setCurrentSong={setCurrentSong}
      isPlaying={isPlaying} 
      setSongs={setSongs}
      libraryStatus={libraryStatus}
      />
      <audio
      onTimeUpdate={timeUpdateHandler} 
      onLoadedMetadata={timeUpdateHandler} 
      ref={audioRef} 
      src={currentSong.audio}
      onEnded={songEndHandler}
      ></audio>
      <LyricsDrawer lyricsDrawerStatus={lyricsDrawerStatus}/>
      {/* <Room/> */}
    </div>
  )
}

export default App

