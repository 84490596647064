import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBook} from "@fortawesome/free-solid-svg-icons";

const LyricsNav = ({setLyricsDrawerStatus, lyricsDrawerStatus}) => {

    
    
    var widget = document.querySelector('.fc-widget-small');
    

    return(
        <nav className={`lyrics-nav ${widget ? '' : 'lyrics-nav-hiding'}`}>
            <button onClick={() => setLyricsDrawerStatus(!lyricsDrawerStatus)} className="lyrics-nav-button">
                Podcast Readings
                <FontAwesomeIcon className="lyrics-icon" icon={faBook}/>
            </button>
        </nav>
    )
       
    
}

export default LyricsNav;