import React from 'react';
import LyricsContent from './LyricsContent';

const LyricsDrawer = ({lyricsDrawerStatus}) => {
    return(
        <div className={`lyrics-drawer ${lyricsDrawerStatus ? 'active-lyrics-drawer' : ''}`}>
            <div className="lyrics-content">
                <LyricsContent/>
            </div>
        </div>
    )
}

export default LyricsDrawer;