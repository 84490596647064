import React from 'react';
// import {faMusic} from "@fortawesome/free-solid-svg-icons";
//Components
import NavItem from './NavItem';
// import DropdownMenu from './DropdownMenu';
//SVGs as Components
// import {ReactComponent as BellIcon} from '../icons/bell.svg';
// import { ReactComponent as MessengerIcon } from '../icons/messenger.svg';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
// import { ReactComponent as CogIcon } from '../icons/cog.svg';

const AdvanceNavbar = ({setLibraryStatus, libraryStatus}) => {
    return(
        <nav className="navbar">
        <ul className= {`navbar-nav ${libraryStatus ? 'navbar-nav-right' : ''}`}>
            <button className="fresh-button" onClick={() => setLibraryStatus(!libraryStatus)}>
            <NavItem icon={<MenuIcon/>}/>
            </button>
            {/* <NavItem icon={<BellIcon />} /> */}
            {/* <NavItem icon={<MessengerIcon />} /> */}

            {/* <NavItem icon={<CogIcon/>}>
                <DropdownMenu/>
            </NavItem> */}
        </ul>
        </nav>
    )
       
    
}

export default AdvanceNavbar;