import React, {useState} from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {faMusic} from "@fortawesome/free-solid-svg-icons";

const NavItem = (props) => {

    const [open, setOpen] = useState(false);

    return(
        <li className="nav-item">
            <a href="#" className="icon-button" onClick={() => setOpen(!open)}>
                {props.icon}
            </a>

            {open && props.children}
        </li>
    )
       
    
}

export default NavItem;